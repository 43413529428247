<template>
  <div class="d-flex flex-column main-layout">
    <the-header />
    <router-view class="flex-grow-1"></router-view>
    <the-footer />
  </div>
</template>

<script>
import TheHeader from "../components/TheHeader.vue";
import TheFooter from "../components/TheFooter.vue";
export default {
  components: {
    TheHeader,
    TheFooter,
  },
};
</script>

<style scoped>
.main-layout {
  min-height: 100vh;
}
</style>
