<template>
  <header
    :class="{ needPadding: needPadding }"
    style="border-bottom: 2px solid rgb(238, 238, 238)"
  >
    <b-navbar
      toggleable="lg"
      type="light"
      variant="light"
      class="d-flex align-items-center justify-content-between"
    >
      <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->
      <!-- <b-navbar-nav class="d-none d-lg-flex" is-nav> -->
      <div class="d-flex align-items-center">
        <b-navbar-brand :to="{ name: 'CourseList' }">
          <img class="logo" src="../assets/images/Sponga_Logo.png" alt="" />
        </b-navbar-brand>
        <div>
          <router-link
            class="btn-courseListText d-block"
            :to="{ name: 'CourseList' }"
          >
            <p
              class="courseListText"
              :class="{ selected: $route.name === 'CourseList' }"
            >
              หน้าแรก
            </p>
          </router-link>
        </div>
      </div>
      <div>
        <button class="btn-verscan" @click="goToLoading">
          <img
            class="to-verscan-img"
            src="../assets/images/toVerscanButton.svg"
            alt="to-verscan"
            Height="44px"
          />
        </button>
      </div>
      <!-- </div> -->

      <!-- <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="d-block d-lg-none">
          <b-nav-item class="mx-2" :to="{ name: 'Curriculum' }">
            <p
              class="text-big"
              :class="{ selected: $route.name === 'CourseList' }"
            >
              หน้าแรก
            </p>
          </b-nav-item>

          <b-nav-item
            class="mx-2"
            :to="{ name: 'MyCourse' }"
            v-if="this.$store.getters.isAuth"
          >
            <p class="text-big">คอร์สเรียนของฉัน</p>
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto d-none d-lg-block">
          <button class="btn-verscan" @click="goToLoading">
            <img src="../assets/images/toVerscanButton.svg" alt="" />
          </button>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto d-block d-lg-none">
          <b-nav-item class="mx-2">
            <button class="btn-verscan" @click="goToLoading">
              <img src="../assets/images/toVerscanButton.svg" alt="" />
            </button>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse> -->
    </b-navbar>
  </header>
</template>

<script>
export default {
  emit: ["reload-login"],
  computed: {
    needPadding() {
      return (
        this.$route.path.includes("/lesson") ||
        this.$route.path.includes("/payment")
      );
    },
    isAuth() {
      return this.$store.getters.isAuth;
    },
  },
  methods: {
    goToLoading() {
      this.$router.push({ name: "Loading" });
    },
    goToLogin() {
      if (this.$route.path.includes("/login")) {
        this.$root.$emit("reload-login");
      } else {
        this.$router.push({ name: "Login" });
      }
    },
    goToProfile() {
      this.$router.push({ name: "Profile" });
    },
    goToVideo() {
      this.$router.push({
        name: "Lesson",
        params: { videoUid: "64a6c17eae8e6b4fc7482642ce4f3ded" },
      });
    },
  },
};
</script>

<style scoped>
.navbar.navbar-light.bg-light {
  background-color: #fff !important;
  padding: 0;
}

.navbar-toggler {
  border: none;
  border-radius: 0;
  position: absolute;
  right: 0;
  top: 0;
  max-height: 76px;
  width: 76px;
  height: 100%;
  /* background-color: #0430ad; */
}

/* .navbar-light >>> .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h44M4 16h44M4 24h44'/%3E%3C/svg%3E") !important;
} */

/* ทำให้เมนูชิดกัน */
/* .navbar >>> .nav-link {
  padding: 0.5rem 0;
} */

.logo {
  height: 58px;
  padding-left: 20px;
  margin: 8px;
}

.needPadding {
  margin-bottom: 50px;
}

p {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

p.selected {
  color: #000;
  border-bottom: 3px solid #ffbb0b;
}

.hello {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 100px;
}

.enroll {
  height: 100%;
  width: 50%;
  max-width: 500px;
  background-color: #07c5f8;
  border: none;
  color: #fff;
  padding: 10px;
}

.login {
  height: 100%;
  width: 100%;
  max-width: 500px;
  background-color: #0430ad;
  border: none;
  color: #fff;
  padding: 10px;
}

.enroll-collapse {
  background-color: #07c5f8;
  border: none;
  color: #fff;
  padding: 10px;
  width: 100%;
}
.login-collapse {
  background-color: #0430ad;
  border: none;
  color: #fff;
  padding: 10px;
  width: 100%;
}
.btn-verscan {
  background: none;
  margin-right: 30px;
}
.to-verscan-img {
  height: 48px;
}
.btn-courseListText {
  background: none;
  margin-left: 15px;
  color: #000;
  text-decoration: none;
}
.courseListText {
  font-size: 20px;
  border-bottom: 3px solid transparent;
}
/* .button-section{
  
} */

@media only screen and (max-width: 768px) {
  .to-verscan-img {
    height: 38px;
  }
  .logo {
    height: 40px;
    padding-left: 10px;
    margin: 2px;
  }
  .to-verscan-img {
    height: 35px;
  }
  .courseListText {
    font-size: 16px;
  }
  .btn-verscan {
    background: none;
    margin-right: 0px;
  }
}

@media only screen and (max-width: 400px) {
  .logo {
    height: 35px;
    padding-left: 5px;
  }
  .to-verscan-img {
    height: 30px;
  }
  .btn-courseListText {
    margin-left: -10px;
  }
  .courseListText {
    font-size: 12px;
  }
  .btn-verscan {
    background: none;
    margin-right: 0px;
  }
}

/* @media only screen and (min-width: 992px) {
  .navbar.navbar-light.bg-light {
    padding: 0.5rem 1rem;
  }
}

@media only screen and (min-width: 1200px) {
  .navbar >>> .nav-link {
    padding: 0.5rem;
  }
} */
</style>
